import React from 'react';
import { view } from '@risingstack/react-easy-state';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { Info, SettingsInputSvideo, Speaker } from '@material-ui/icons';
import { Link, useLocation  } from 'react-router-dom';

const Nav = () => {
    const location = useLocation();

    return (
        <BottomNavigation value={location.pathname} style={{bottom: 0, left: 0, right: 0, position: 'fixed'}}>
            <BottomNavigationAction label="Steuerung" value="/" icon={<SettingsInputSvideo />} component={Link} to="/" />
            <BottomNavigationAction label="Spotify" value="/spotify" icon={<Speaker />} component={Link} to="/spotify"/>
            <BottomNavigationAction label="Infos" value="/infos" icon={<Info />} component={Link} to="/infos"/>
        </BottomNavigation>
    )
}

export default view(Nav);
