import axios from 'axios';
import appStore from './appStore';
import qs from 'qs';
import { clientId, clientSecret, redirectUri } from './const';
import https from 'https';

const agent = new https.Agent({
    rejectUnauthorized: false
});

const hueApi = axios.create({
    httpsAgent: agent,
    baseURL: 'http://192.168.178.28/api/wAnMC5ZkrI5JB5ipzf3ETdhBVcPDLU4shSZAUKKj'
});

const maxApi = axios.create({
    baseURL: 'https://api.max.graphics/api.json',
});
const spotifyApi = axios.create({
    baseURL: 'https://api.spotify.com/v1'
});

export async function fetchLights(filter) {
    const { data } = await hueApi.get('/lights');
    return data;
}

export async function setLight(id, state) {
    const { data } = await hueApi.put(`/lights/${id}/state`, state);
    return data;
}

export async function fetchMax() {
    const { data } = await maxApi.get();
    return data;
}

export async function getSpotify(endpoint) {

    const response = await spotifyApi.get(endpoint, {
        headers: {
            'Authorization': `Bearer ${appStore.spotify.token}`
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        return error.response.data;
    });

    return response;
};


export const getSpotifyRefreshToken = async (code) => {
    const headers = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
            username: clientId,
            password: clientSecret,
        }
    };
    const data = {
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: redirectUri
    };

    try {
        const response = await axios.post(
            'https://accounts.spotify.com/api/token',
            qs.stringify(data),
            headers
        );
            return response.data
        } catch (error) {
            console.log(error);

            return error
        }
};

export const getSpotifyNewAccessToken = async () => {
    const headers = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
            username: clientId,
            password: clientSecret,
        }
    };
    const data = {
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('spotify_refresh_token')
    };

    try {
        const response = await axios.post(
            'https://accounts.spotify.com/api/token',
            qs.stringify(data),
            headers
        );
            return response.data
        } catch (error) {
            console.log(error);
        }
};

export async function setSpotifyVolume(id, value) {
    return await spotifyApi.put(`/me/player/volume?volume_percent=${value}${id !== null ? `&device_id=${id}` : ''}`, null, {
        headers: {
            'Authorization': `Bearer ${appStore.spotify.token}`
        }
    });
};

export async function putSpotify(endpoint, data) {
    return await spotifyApi.put(endpoint, data, {
        headers: {
            'Authorization': `Bearer ${appStore.spotify.token}`
        }
    });
}

export async function postSpotify(endpoint) {
    return await spotifyApi.post(endpoint, null, {
        headers: {
            'Authorization': `Bearer ${appStore.spotify.token}`
        }
    });
}