export const clientId = "d930d8c28a4749d29af1d1773dac1ad0";
export const clientSecret = '87f738aa52e44d838099dcaa0b521d38';
// export const scopes = [
//     "user-read-currently-playing",
//     "user-read-private",
//     "user-read-email",
//     "streaming",
//     "user-modify-playback-state",
//     "user-read-playback-state",
//     "user-read-recently-played"
// ];
export const scopes = [
    "ugc-image-upload",
    "user-read-recently-played",
    "user-top-read",
    "user-read-playback-position",
    "user-read-playback-state",
    "user-modify-playback-state",
    "user-read-currently-playing",
    "app-remote-control",
    "streaming",
    "playlist-modify-public",
    "playlist-modify-private",
    "playlist-read-private",
    "playlist-read-collaborative",
    "user-follow-modify",
    "user-follow-read",
    "user-library-modify",
    "user-library-read",
    "user-read-email",
    "user-read-private"
];

const url = document.createElement('a');
url.setAttribute('href', window.location.href);

export const redirectUri = `${url.protocol}//${url.hostname}${url.port && `:${url.port}`}/spotify`;