import {Grid, Typography } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React, { Component } from "react";
import appStore from "../appStore";
import styled from "styled-components";

class SpotifyPlaylist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            tracks: []
        };
    }

    async componentDidMount() {
        try {
            const playlistObject = await appStore.spotifyAPI(`/playlists/${appStore.spotify.current.playlistId}`);

            this.setState({
                name: playlistObject.name,
                tracks: playlistObject.tracks.items,
                currentTrack: appStore.spotify.current.currentTitle,
                contextUri: appStore.spotify.current.contextUri
            });
        } catch (e) {
            console.log(e);
        }

        this.intervalID = setInterval(() =>
            appStore.spotify.updateAPIs(),
            10000
        );
    }

    componentWillUnmount(){
        clearInterval(this.intervalID);
    }


    listItemFunction(item, index) {
        const {track} = item;

        if (!track) {
            return;
        }

        return (
            <ListItem key={track.id} isActive={track.id === appStore.spotify.current.currentTitle} onClick={() => this.play(track.id, index)} item xs={12} sm={6} md={4} lg={3}>
                <img src={track.album.images[track.album.images.length - 1].url} alt="" width={64} />
                <p>
                    <strong>{track.name}</strong><br/>
                    {track.artists.map(artist => artist.name)}
                </p>
            </ListItem>
        )
    }

    async play(id, index) {
        await appStore.putSpotifyAPI('/me/player/play', {
            "context_uri": this.state.contextUri,
            "offset": {
                "position": index
            },
            "position_ms": 0
        });

        this.setState({
            currentTrack: id,
        })
    }

    render() {

        return (
            <SpotifyWrapper>
                <Typography component="h1" variant="h3">
                    {this.state.name}
                </Typography>
                <Grid container spacing={3}>
                    {this.state.tracks.map((item, index) => this.listItemFunction(item, index))}
                </Grid>
            </SpotifyWrapper>
        );
    }
}

const SpotifyWrapper = styled.div`
    /* margin-top: 1em;
    padding-top: 2em;
    border-top: 1px solid rgba(255,255,255,0.5); */
`;

const ListItem = styled(Grid)`
    text-align: left;
    color: ${props => props.isActive ? 'green' : 'white'};
    line-height: 1.5;
    display: flex;
    align-items: center;
    /* margin: 0.5rem auto; */
    cursor: pointer;

    img {
        margin-right: 2rem;
    }
`;

export default view(SpotifyPlaylist);