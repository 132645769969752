import React from 'react';
import { view } from '@risingstack/react-easy-state';
import appStore from '../appStore';
import Light from './Light';
import { Grid } from '@material-ui/core';
import Colorpicker from './Colorpicker';

// this is re-rendered whenever the relevant parts of the used data stores change
const LightList = () => (
  <Grid container spacing={3}>
    {Object.keys(appStore.lights).map((keyName, i) => (
        <Light key={i} id={keyName} {...appStore.lights[keyName]} />
      ))}
    <Colorpicker />
  </Grid>
);

export default view(LightList);
