import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import styled from 'styled-components';

class Clock extends Component {
    constructor(props){
        super(props);
        //This declared the state of time at the very beginning
        this.state ={
            time: new Date().toLocaleTimeString(),
            date: new Date().toLocaleDateString()
        }
    }

    //This happens when the component mount and the setInterval function get called with a call back function updateClock()
    componentDidMount() {
        this.intervalID = setInterval(() =>
            this.updateClock(),
            1000
        );
    }

    //This section clears setInterval by calling intervalID so as to optimise memory
    componentWillUnmount(){
        clearInterval(this.intervalID)
    }

    //This function set the state of the time to a new time
    updateClock(){
        this.setState({
            time: new Date().toLocaleTimeString(),
            date: new Date().toLocaleDateString()
        });
    }
    render() {

        return (
            <StyledGrid item xs={12}>
                <StyledTime>{this.state.time}</StyledTime>
                <StyledDate>{this.state.date}</StyledDate>
            </StyledGrid>
        );
    }
}

const StyledTime = styled.p`
    font-size: 3rem;
    line-height: 1;
    margin: 0;
`;

const StyledDate = styled.p`
    font-size: 3rem;
    line-height: 1;
    margin: 2vh auto 10vh auto;
    font-weight: 300;
`;

const StyledGrid = styled(Grid)`
    padding-top: 5vh !important;
`;

export default Clock;