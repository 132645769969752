import React from 'react';
import { view } from '@risingstack/react-easy-state';
import appStore from '../appStore';
import { Grid } from '@material-ui/core';
import { Pool } from '@material-ui/icons';
import styled from 'styled-components';

// this is re-rendered whenever the relevant parts of the used data stores change
class Eiskanal extends React.Component {
    render() {
        const {eiskanal} = appStore.max;

        return eiskanal ? (
            <Grid item md={4} xs={6}>
                <StyledIcon />
                <p>
                <StyledNumber>{eiskanal.temp} C°</StyledNumber>
                    {eiskanal.date}
                </p>
            </Grid>
        ) : (
            'Wetterdaten konnten nicht geladen werden'
        );
    }
}

const StyledIcon = styled(Pool)`
    font-size: 3em !important;
`;

const StyledNumber = styled.strong`
    font-size: 1.5rem;
    display: block;
`;
export default view(Eiskanal);
