import React from 'react';
import { view } from '@risingstack/react-easy-state';
import appStore from '../appStore';
import { Grid } from '@material-ui/core';
import { Healing } from '@material-ui/icons';
import styled from 'styled-components';

// this is re-rendered whenever the relevant parts of the used data stores change
class Corona extends React.Component {
    render() {
        const {corona} = appStore.max;

        return corona ? (
            <Grid item md={4} xs={6}>
                <StyledIcon  width={100}/>
                <p>
                    <StyledNumber>{corona.current['Augsburg Stadt'].toFixed(1)}</StyledNumber>
                    Corona in Augsburg
                </p>
            </Grid>
        ) : (
            'Coronadaten konnten nicht geladen werden'
        );
    }
}

const StyledIcon = styled(Healing)`
    font-size: 3rem !important;
`;
const StyledNumber = styled.strong`
    font-size: 1.5rem;
    display: block;
`;
export default view(Corona);
