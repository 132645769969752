import React from 'react';
import { view } from '@risingstack/react-easy-state';
import { Grid } from '@material-ui/core';
import { Speaker } from '@material-ui/icons';
import styled from 'styled-components';

// this is re-rendered whenever the relevant parts of the used data stores change
const SpotifyDevice = ({
    name,
    id,
    is_active,
    handleClick
}) => {
    return (
        <StyledGrid item md={4} xs={6}>
            <Speaker fontSize="large" onClick={() => handleClick(id)} style={{color: is_active ? 'green' : 'white'}}/>
            <p>
                {name}
            </p>
        </StyledGrid>
    );
};

const StyledGrid = styled(Grid)`
    cursor: pointer;
`;

export default view(SpotifyDevice);
