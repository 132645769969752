import React from 'react';
import { view } from '@risingstack/react-easy-state';
import appStore from '../appStore';
import { SwatchesPicker  } from 'react-color';
import { Grid, Modal } from '@material-ui/core';
import { Colorize } from '@material-ui/icons';

// this is re-rendered whenever the relevant parts of the used data stores change
const ColorPicker = () => {
    const getXY = (rgb) => {
        let red = rgb.r,
            green = rgb.g,
            blue = rgb.b;

        if (red > 0.04045){
        red = Math.pow((red + 0.055) / (1.0 + 0.055), 2.4);
        }
        else red = (red / 12.92);

        if (green > 0.04045){
        green = Math.pow((green + 0.055) / (1.0 + 0.055), 2.4);
        }
        else green = (green / 12.92);

        if (blue > 0.04045){
        blue = Math.pow((blue + 0.055) / (1.0 + 0.055), 2.4);
        }
        else blue = (blue / 12.92);

        var X = red * 0.664511 + green * 0.154324 + blue * 0.162028;
        var Y = red * 0.283881 + green * 0.668433 + blue * 0.047685;
        var Z = red * 0.000088 + green * 0.072310 + blue * 0.986039;
        var x = X / (X + Y + Z);
        var y = Y / (X + Y + Z);
        return [x,y];
    };

    const onColorChange = (color) => {
        appStore.color = color;
        appStore.xyColor = getXY(color.rgb);

        console.log(color);

        Object.keys(appStore.lights).map((keyName, i) => (
            (appStore.lights[keyName].state.colormode && appStore.lights[keyName].state.on)
            && appStore.setLight(keyName, {'xy' : appStore.xyColor})
        ));
    };

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Grid item md={4} xs={6}>
            <div onClick={handleOpen}>
                <Colorize fontSize="large" />
                <p>
                    Farben
                </p>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <SwatchesPicker color={ appStore.color } onChangeComplete={ onColorChange } />
            </Modal>
        </Grid>
    );
};

export default view(ColorPicker);


