import React from 'react';
import './App.css';
import LightList from './components/LightList';
import appStore from './appStore';
import styled from 'styled-components';
import Wetter from './components/Wetter';
import Corona from './components/Corona';
import { Container, Grid, Typography } from '@material-ui/core';
import Spotify from './components/Spotify';
import Eiskanal from './components/Eiskanal';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Nav from './components/Nav';
import Clock from './components/Clock';
import SpotifyPlaylist from './components/SpotifyPlaylist';
class App extends React.Component {
  componentDidMount() {
    this.updateAPIs();

    this.intervalID = setInterval(() =>
      this.updateAPIs(),
      60000
    );
  }


  //This section clears setInterval by calling intervalID so as to optimise memory
  componentWillUnmount(){
      clearInterval(this.intervalID)
  }

  //This function set the state of the time to a new time
  updateAPIs(){
    appStore.fetchLights();
    appStore.getMax();
  }

  render() {
    return (
      <AppWrapper>
        <Router>
          <Container style={{paddingBottom: 80}}>
            <Switch>
              <Route exact path="/infos">
                <Grid container spacing={3}>
                  <Clock />
                  <Wetter />
                  <Corona />
                  <Eiskanal />
                </Grid>
              </Route>
              <Route exact path="/spotify/playlist" render={(props) => <SpotifyPlaylist {...props} /> } />
              <Route exact path="/spotify" render={() => <Spotify />} />
              <Route path="/">
                  <Typography component="h1" variant="h3">
                    Lampen
                  </Typography>
                  <LightList />
              </Route>
            </Switch>
          </Container>
          <Nav />
        </Router>
      </AppWrapper>
    );
  }
}

const AppWrapper = styled.div`
  color: #ffffff;
  text-align: center;
`;

export default App;
