import { Grid, LinearProgress, Slider } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React, { Component } from "react";
import appStore from "../appStore";
import { clientId, redirectUri, scopes } from "../const";
import SpotifyDevice from './SpotifyDevice';
import {getQueryStringValue} from '../helpers';
import styled from "styled-components";
import { Pause, PlayArrow, PlaylistPlay, SkipNext, SkipPrevious, VolumeDown, VolumeUp } from "@material-ui/icons";
import { Link } from "react-router-dom";
export const authEndpoint = 'https://accounts.spotify.com/authorize';

class Spotify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noSpotify: true,
            is_playing: false,
            progress_ms: 0,
            item: {
                album: {
                    images: [
                        {
                            url: ''
                        }
                    ]
                },
                name: '',
                artists: [
                    {
                        name: ''
                    }
                ],
                duration_ms: 0
            },
            playlist: {
                name: '',
                tracks: {
                    items: []
                }
            },
            playlistID: '',
            device: {
                id: '',
                volume_percent: 0
            },
            timer: {
                time: null
            },
            devices: []
        };

        this.playStop = this.playStop.bind(this);
        this.onChangeVolume = this.onChangeVolume.bind(this);
        this.setActiveDevice = this.setActiveDevice.bind(this);
        this.setDevices = this.setDevices.bind(this);
        this.updateAPIs = this.updateAPIs.bind(this);
    }

    async componentDidMount() {
        let token = localStorage.getItem('spotify_access_token');

        if (!token) {
            const code = getQueryStringValue('code');

            if (code) {
                try {
                    await appStore.getSpotifyRefreshToken(code);
                }
                catch(e) {
                    console.log(e);
                    return;
                }
            } else {
                return;
            }
        } else {
            appStore.spotify.token = token;
        }

        if (appStore.spotify.token) {
            this.setDevices();
            this.updateAPIs();
        }


        this.intervalID = setInterval(() =>
            this.updateAPIs(),
            300
        );

        appStore.spotify.updateAPIs = this.updateAPIs;
    }

    async setDevices() {
        const devices = await appStore.getSpotifyDevices();

        this.setState({
            devices: devices
        });
    }

     //This section clears setInterval by calling intervalID so as to optimise memory
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }

    //This function set the state of the time to a new time
    async updateAPIs (){
        try {
            const data = await appStore.spotifyAPI('/me/player');
            let playlistId = '';

            if (data.context) {
                let playlistIdArray = data.context.uri.split(':');

                playlistId = playlistIdArray[playlistIdArray.length - 1];
            }

            this.setState({
                noSpotify: false,
                playlistID: playlistId,
                ...data,
            });

            appStore.spotify.current = {
                playlistId: playlistId,
                currentTitle: data.item.id,
                contextUri: data.context.uri
            }
        } catch (e) {
            this.setState({
                noSpotify: true
            })
        }


    }

    millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    async playStop() {
        const isPlaying = !this.state.is_playing;

        await appStore.putSpotifyAPI(isPlaying ? '/me/player/play' : '/me/player/pause');

        this.setState({
            is_playing: isPlaying
        });
    }

    async prevNextTrack(endpoint) {
        await appStore.postSpotifyAPI(`/me/player/${endpoint}`);
    }

    async setActiveDevice(id) {
        await appStore.putSpotifyAPI('/me/player', {
            device_ids: [
                id
            ]
        });

        setTimeout(await this.setDevices, 100)
    }

    onChangeVolume (event, value) {
        this.setState({
            device: {
                volume_percent: value
            }
        })

        clearTimeout(this.state.timer.time);

        this.setState({
            timer: {
                time: setTimeout(() => {
                    appStore.setSpotifyVolume(null, this.state.device.volume_percent);
                }, 100)
            }
        })
    };



    render() {
        const {token} = appStore.spotify;

        return (
            <SpotifyWrapper>
                {!token && (
                    <a
                    className="btn btn--loginApp-link"
                    href={`${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=code&show_dialog=true`}
                    >
                    Login to Spotify
                    </a>
                )}
                {token && (
                    <>
                        {!this.state.noSpotify && (
                            <>
                                <StyledImg src={this.state.item.album.images[0].url} height={300} alt=""/><br/>
                                <strong>
                                    {this.state.item.name}
                                </strong><br/>
                                {this.state.item.artists.map(artist => artist.name)}<br/>
                                <RoundButton onClick={() => this.prevNextTrack('previous')}>
                                    <SkipPrevious />
                                </RoundButton>
                                {!this.state.is_playing ? <RoundButton onClick={this.playStop}><PlayArrow /></RoundButton> : <RoundButton onClick={this.playStop}><Pause /></RoundButton>}
                                <RoundButton onClick={() => this.prevNextTrack('next')}>
                                    <SkipNext />
                                </RoundButton>
                                <Link to={{
                                    pathname: `/spotify/playlist`
                                }}>
                                    <RoundButton>
                                        <PlaylistPlay />
                                    </RoundButton>
                                </Link>

                                <Spacer />

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        {this.millisToMinutesAndSeconds(this.state.progress_ms)}
                                    </Grid>
                                    <Grid item xs>
                                        <LinearProgress  variant="determinate" value={this.state.progress_ms * 100 / this.state.item.duration_ms} />
                                    </Grid>
                                    <Grid item>
                                        {this.millisToMinutesAndSeconds(this.state.item.duration_ms)}
                                    </Grid>
                                </Grid>
                                <Spacer />
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <VolumeDown />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider value={this.state.device.volume_percent} step={1} onChange={this.onChangeVolume} aria-labelledby="continuous-slider" max={100} min={0}/>
                                    </Grid>
                                    <Grid item>
                                        <VolumeUp />
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Spacer />

                        <Grid container spacing={3}>
                            {this.state.devices.map(device => <SpotifyDevice key={device.id} {...device} handleClick={this.setActiveDevice}/>)}
                        </Grid>
                    </>
                )}
            </SpotifyWrapper>
        );
    }
}

const SpotifyWrapper = styled.div`
    margin-top: 2em;
`;

const Spacer = styled.div`
    display:block;
    margin: 1rem auto;
`;

const RoundButton = styled.button`
    outline: none;
    background:transparent;
    border: 1px solid white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: white;
    cursor: pointer;
    margin: 2rem 0.5rem 1rem 0.5rem;
`;

const StyledImg = styled.img`
    margin-bottom: 1rem;
`;


export default view(Spotify);