import { store } from '@risingstack/react-easy-state';
import * as api from './api';

// use 'appStore' instead of 'this' in the store methods to make them passable as callbacks
const appStore = store({
    lights: [],
    max: {},

    async fetchLights(filter) {
        appStore.lights = [];
        appStore.lights = await api.fetchLights(filter);
    },

    async setLight(id, state) {
        await api.setLight(id, state);
    },

    color: {},

    async getMax() {
        appStore.max = await api.fetchMax();
    },

    spotify: {
        token: null
    },

    async getSpotifyDevices() {
        let spotifyDevices = await api.getSpotify('/me/player/devices');

        if (spotifyDevices.error) {
            const newToken = await api.getSpotifyNewAccessToken();

            if (newToken.access_token) {
                localStorage.setItem('spotify_access_token', newToken.access_token );
                appStore.spotify.token = newToken.access_token;

                spotifyDevices = await api.getSpotify('/me/player/devices');

            } else {
                appStore.spotify.token = '';
                appStore.spotify.refresh_token = '';

                localStorage.removeItem('spotify_access_token');
                localStorage.setItem('spotify_refresh_token');

                return;
            }
        }

        if (!spotifyDevices.devices) {
            return;
        }

        return spotifyDevices.devices;

    },
    async getSpotifyRefreshToken(code) {
        const data = await api.getSpotifyRefreshToken(code);

        if (data.access_token) {
            appStore.spotify.token = data.access_token;
            appStore.spotify.refresh_token = data.refresh_token;

            localStorage.setItem('spotify_access_token', data.access_token );
            localStorage.setItem('spotify_refresh_token', data.refresh_token );
        }
    },

    async setSpotifyVolume(id, value) {
        await api.setSpotifyVolume(id, value);
    },

    async spotifyAPI(endpoint) {
        let data = await api.getSpotify(endpoint);

        if (data.error) {
            const newToken = await api.getSpotifyNewAccessToken();

            if (newToken.access_token) {
                localStorage.setItem('spotify_access_token', newToken.access_token );
                appStore.spotify.token = newToken.access_token;

                data = await api.getSpotify(endpoint);

            } else {
                appStore.spotify.token = '';
                appStore.spotify.refresh_token = '';

                localStorage.removeItem('spotify_access_token');
                localStorage.setItem('spotify_refresh_token');

                return;
            }
        }

        return data;
    },

    async putSpotifyAPI(endpoint, data = null) {
        await api.putSpotify(endpoint, data);

        return;
    },

    async postSpotifyAPI(endpoint) {
        const data = await api.postSpotify(endpoint);

        return data;
    },
});

export default appStore;
