import React from 'react';
import { view } from '@risingstack/react-easy-state';
import appStore from '../appStore';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

// this is re-rendered whenever the relevant parts of the used data stores change
class Wetter extends React.Component {
    constructor(props){
        super(props);
        //This declared the state of time at the very beginning
        this.state = {
            wetter: {}
        }
    }
     //This happens when the component mount and the setInterval function get called with a call back function updateClock()
     componentDidMount() {
        this.intervalID = setInterval(() =>
            this.updateWeather(),
            1000
        );
    }

    //This section clears setInterval by calling intervalID so as to optimise memory
    componentWillUnmount(){
        clearInterval(this.intervalID)
    }

    //This function set the state of the time to a new time
    updateWeather(){
        this.setState({
            time: new Date().toLocaleTimeString(),
            date: new Date().toLocaleDateString()
        });
    }

    render() {
        const {wetter} = appStore.max;

        return wetter ? (
            <Grid item md={4} xs={6}>
                <StyledImg src={wetter.icon} alt="" /><br/>
                <StyledNumber>{wetter.temp} C°</StyledNumber>
                {wetter.description}
            </Grid>
        ) : (
            'Wetterdaten konnten nicht geladen werden'
        );
    }
}

const StyledNumber = styled.strong`
    font-size: 1.5rem;
    display: block;
`;

const StyledImg = styled.img`
    width: 4rem;
`;

export default view(Wetter);

