import React from 'react';
import { view, store } from '@risingstack/react-easy-state';
import appStore from '../appStore';
import Slider from '@material-ui/core/Slider';
import { Grid } from '@material-ui/core';
import { EmojiObjects, EmojiObjectsOutlined, FiberManualRecord, FiberManualRecordOutlined, LocalCafe, LocalCafeOutlined } from '@material-ui/icons';

// this is re-rendered whenever the relevant parts of the used data stores change
const Light = ({
    id,
    name,
    state,
    uniqueid
}) => {

    const light = store(state);
    const timer = store({time: null});

    const onChangeBri = (event, value) => {
        light.bri = value;

        clearTimeout(timer.time);

        timer.time = setTimeout(() => {
            appStore.setLight(id, {'bri': light.bri});
        }, 100);
    };

    const onClickOn = () => {
        light.on = !light.on

        let stateSettings = {
            'on': light.on
        }

        if (light.colormode && light.on) {
            stateSettings.xy = appStore.xyColor;
        }

        appStore.setLight(id, stateSettings)
    }

    return (
        <Grid item md={4} xs={6}>
            <div onClick={onClickOn}>
                {uniqueid ===  '7c:b0:3e:aa:0a:09:c9:eb-03' ? (
                    light.on ? (
                        <LocalCafe fontSize="large" />
                    ) : (
                        <LocalCafeOutlined fontSize="large" />
                    )
                ) : (
                    light.on ? (
                        <EmojiObjects fontSize="large" />
                    ) : (
                        <EmojiObjectsOutlined fontSize="large" />
                    )
                )}
                <p>
                    {name}
                </p>
            </div>
            {(light.on && light.bri) && (
                <Grid container spacing={2}>
                    <Grid item>
                        <FiberManualRecordOutlined />
                    </Grid>
                    <Grid item xs>
                        <Slider value={light.bri} step={1} onChange={onChangeBri} aria-labelledby="continuous-slider" max={254} min={1}/>
                    </Grid>
                    <Grid item>
                        <FiberManualRecord />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default view(Light);
